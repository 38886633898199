import Carousel, { RenderArrowProps, RenderPaginationProps } from '@itseasy21/react-elastic-carousel';
import styled from 'styled-components';

import ChevronLeft from '@splunk/react-icons/ChevronLeft';
import ChevronRight from '@splunk/react-icons/ChevronRight';
import Button from '@splunk/react-ui/Button';

import React, { FC, ReactNode } from 'react';

import AppCardWithRating from '@/components/AppCardWithRating/AppCardWithRating';
import PaginationButton from '@/components/PaginationButton/PaginationButton';
import { AppCardData } from '@/types/splunkbaseTypes';

export interface AppsCarouselProps {
  apps: AppCardData[];
}

const StyledButton = styled(Button)`
  background-color: #27292e !important;
  font-size: 0.8rem !important;
  color: white !important;
  width: 1rem !important;
  height: 1rem !important;
  border: 0;
  top: 40%;
  &:disabled {
    visibility: hidden;
    cursor: none;
  }
`;

const CarouselContainer = styled(Carousel)<{ children: ReactNode }>`
  position: relative;
  .rec-carousel {
    min-height: 265px;
  }
  .rec-carousel .rec-item-wrapper {
    min-width: 289.5px;
  }
`;

function renderArrow({ type, onClick, isEdge }: RenderArrowProps) {
  return (
    <StyledButton onClick={onClick} disabled={isEdge}>
      {type === 'PREV' ? <ChevronLeft /> : <ChevronRight />}
    </StyledButton>
  );
}

function renderPagination({ pages, activePage, onClick }: RenderPaginationProps) {
  const shouldBeVisible = pages.length > 1;
  return (
    <div className="pagination-styling">
      {pages.map((page) => {
        const isActivePage = activePage === page;
        return (
          <PaginationButton
            key={page}
            onClick={() => onClick(`${page}`)}
            active={isActivePage}
            visible={shouldBeVisible}
          />
        );
      })}
    </div>
  );
}

const AppsCarousel: FC<AppsCarouselProps> = ({ apps }) => {
  const renderCollection =
    apps?.map((card, i) => (
      <div key={i}>
        <AppCardWithRating app={card} />
      </div>
    )) || [];

  return (
    <CarouselContainer
      itemsToScroll={4}
      itemsToShow={4}
      isRTL={false}
      itemPadding={[10, 10]}
      renderArrow={renderArrow}
      renderPagination={renderPagination}
      showEmptySlots
    >
      {renderCollection}
    </CarouselContainer>
  );
};

export default AppsCarousel;
