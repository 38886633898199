/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import _ from 'lodash';
import styled from 'styled-components';

import Popover from '@splunk/react-ui/Popover';
import Text from '@splunk/react-ui/Text';
import variables from '@splunk/themes/variables';

import { useRouter } from 'next/router';

import React, { useCallback, useState } from 'react';

import Autocomplete from '@/components/Autocomplete/Autocomplete';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';
import { fetchSuggestions } from '@/lib/api/api_v3/suggestions';
import { sendGTMViewSearchResult } from '@/lib/sharedFunctions';
import { contentGradient } from '@/styles/layout';
import { device } from '@/styles/media_setting';
import { Suggestion } from '@/types/splunkbaseTypes';

const SearchStyle = styled(Text)`
  height: 40px;
  @media ${device.contentW} {
    width: 540px;
  }
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-image: ${contentGradient.dividerGradient};
  color: ${variables.contentColorActive};
  font-size: 18px;
`;

let cancelToken: AbortController;

const NavBarSearchHome: React.FC = () => {
  const [query, setQuery] = useState('');
  const [, setSearchQuery] = useState({});

  const [searchSuggestions, setSearchSuggestions] = useState<Suggestion[]>([]);
  const [displayItems, setDisplayItems] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<HTMLInputElement>();
  const anchorRef = useCallback((el: HTMLInputElement) => setAnchor(el), []);
  const handleRequestClose = useCallback(() => {
    setDisplayItems(false);
    setQuery('');
  }, []);

  const router = useRouter();
  const {
    query: { categories, filters },
  } = router;

  const sendQuery = async (value: string) => {
    if (value && value.length > 0) {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.abort();
      }

      try {
        cancelToken = new AbortController();
        const suggestions = await fetchSuggestions(
          {
            q: value,
          },
          cancelToken.signal
        );

        if (suggestions.length) {
          setSearchSuggestions([...suggestions]);
        }
      } catch (err) {
        if (axios.isCancel(err)) console.log(`request cancelled: ${err}`);
        else console.log('cancel error');
      }
    }
  };

  const onSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.MouseEvent<HTMLSpanElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const keyword = !!target?.value?.length ? target.value : '';

    if (!target.type || target.type !== 'text') setQuery('');
    else {
      setQuery(keyword);
      setDisplayItems(true);

      const search = _.debounce(sendQuery, 50);

      setSearchQuery((prevSearch: { cancel: () => void }) => {
        if (prevSearch.cancel) {
          prevSearch.cancel();
        }
        return search;
      });

      search(keyword);
    }
  };

  const onSubmit: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const keycode = event.code as string;
    if (keycode === 'Enter' && query && query.length > 0) {
      sendGTMViewSearchResult(query, 'Enter');
      router.push(
        {
          pathname: '/apps',
          query: {
            keyword: query,
            ...(categories && { categories: categories }),
            ...(filters && { filters: filters }),
          },
        },
        undefined,
        { shallow: true }
      );
      setDisplayItems(false);
      setQuery('');
    }
  };

  return (
    <>
      <SearchStyle
        data-test="textbox"
        appearance="search"
        placeholder="Search for apps"
        value={query}
        onChange={onSearchChange}
        onFocus={() => {
          setDisplayItems(true);
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onKeyDown={onSubmit}
        elementRef={anchorRef}
      />
      <ErrorBoundary>
        {query && query.length > 0 && displayItems && (
          <Popover
            data-test="autocomplete"
            open={displayItems}
            anchor={anchor}
            onRequestClose={handleRequestClose}
            appearance="none"
            placeholder=""
            animation
            autoCloseWhenOffScreen={false}
            closeReasons={['escapeKey', 'tabKey']}
            style={{
              marginLeft: '1.5%',
              width: '100%',
              background: 'transparent',
              boxShadow: '15px 30px 30px 15px #08090A',
              borderRadius: '5px',
            }}
          >
            <Autocomplete
              onContentClick={handleRequestClose}
              searchQuery={query}
              results={searchSuggestions}
              width={540}
            />
          </Popover>
        )}
      </ErrorBoundary>
    </>
  );
};

export default NavBarSearchHome;
