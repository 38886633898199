import styled from 'styled-components';

import Card from '@splunk/react-ui/Card';
import variables from '@splunk/themes/variables';

import Image from 'next/image';

import React, { useContext } from 'react';

import Rating from '@/components/Rating/Rating';
import { ProductContext } from '@/lib/appContext';
import { getResourceUrl } from '@/lib/resourceHelper';
import { getDisplayableProducts, sendGTMClickSearchResult } from '@/lib/sharedFunctions';
import { PageHeader } from '@/styles/layout';
import { AppCardAuthor, AppCardPlatform, AppCardText } from '@/styles/typography';
import type { AppCardData, Filter } from '@/types/splunkbaseTypes';

const StyledCardPadding = styled.div`
  padding: 16px 16px 0 16px;
  height: 197px;
  border-bottom: 0.2px solid;
`;

const StyledColumns = styled.div`
  grid-column-start: 2;
`;

const StyledImage = styled.div`
  grid-column-start: 1;
  width: 48px;
  height: 48px;
`;

const AppCardTitle = styled(AppCardText)`
  color: ${variables.contentColorActive};
  height: 34px;
  font-weight: ${variables.fontWeightBold};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledAuthorText = styled(AppCardAuthor)`
  color: ${variables.contentColorMuted};
`;

const StyledProduct = styled.div`
  margin-top: 11px;
  color: ${variables.contentColorMuted};
  display: flex;
  justify-content: space-between;
`;

const StyledProductValue = styled(AppCardPlatform)`
  text-transform: capitalize;
  color: ${variables.contentColorMuted};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 10px;
`;

const StyledSupportSection = styled.div`
  padding: 8px 0;
`;

const StyledSupportContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSupportText = styled(AppCardPlatform)`
  padding-left: 5px;
`;

const AppCardDescription = styled(AppCardText)`
  margin-top: 15px;
  color: ${variables.contentColorMuted};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export interface AppCardWithRatingProps {
  app: AppCardData;
  searchQuery?: string; //For Google Metric tracking
}

const AppCardWithRating: React.FC<AppCardWithRatingProps> = ({ app, searchQuery }) => {
  if (app.support === 'premium') app.support = 'splunk';

  const notArchived = app.support === 'not_supported' ? 'not supported' : app.support + ' supported ' + app.app_type;

  const supportDeal = app.is_archived ? 'archived ' + app.app_type : notArchived;
  const availableProducts: Filter[] = useContext(ProductContext).availableProducts;
  return (
    <Card
      to={`/app/${app.id}`}
      key={app.id}
      style={{ width: '255px', height: '245px' }}
      onClick={() => {
        if (searchQuery) {
          sendGTMClickSearchResult(searchQuery, 'Enter', `${window.location.host}/app/${app.id}`);
        }
      }}
    >
      <StyledCardPadding>
        <PageHeader>
          <StyledImage>
            {app.icon_url && (
              <Image
                src={getResourceUrl(app.icon_url)}
                width="48"
                height="48"
                alt={`${app.title} app icon`}
                unoptimized={true}
              />
            )}
          </StyledImage>
          <StyledColumns>
            <AppCardTitle data-test="app-card-title">{app.title}</AppCardTitle>
            <StyledAuthorText>{'By ' + app.by}</StyledAuthorText>
          </StyledColumns>
        </PageHeader>
        <AppCardDescription>{app.description}</AppCardDescription>
        <StyledProduct>
          <AppCardPlatform>platform</AppCardPlatform>
          <StyledProductValue>
            {app && app.product_types && getDisplayableProducts(app.product_types, availableProducts)}
          </StyledProductValue>
        </StyledProduct>
        <StyledProduct>
          <AppCardPlatform>rating</AppCardPlatform>
          <Rating average={app.rating ? app.rating.average : 0} total={app.rating ? app.rating.count : 0} />
        </StyledProduct>
      </StyledCardPadding>
      <StyledSupportSection>
        <StyledSupportContainer>
          {!app.is_archived && app.support && (
            <Image
              src={getResourceUrl(`/apps/${app.support}.svg`)}
              width="20"
              height="20"
              alt={`${app.title} support icon`}
            />
          )}
          <StyledSupportText>{supportDeal}</StyledSupportText>
        </StyledSupportContainer>
      </StyledSupportSection>
    </Card>
  );
};

export default AppCardWithRating;
