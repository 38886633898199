import Carousel, { RenderArrowProps, RenderPaginationProps } from '@itseasy21/react-elastic-carousel';
import styled from 'styled-components';

import ChevronLeft from '@splunk/react-icons/ChevronLeft';
import ChevronRight from '@splunk/react-icons/ChevronRight';
import Button from '@splunk/react-ui/Button';
import { SplunkThemeProvider } from '@splunk/themes';

import React, { FC } from 'react';

import CollectionCard from '@/components/CollectionCard/CollectionCard';
import PaginationButton from '@/components/PaginationButton/PaginationButton';
import type { Collection } from '@/types/splunkbaseTypes';

export interface CollectionsProps {
  collections: Collection[];
}

const StyledButton = styled(Button)`
  background-color: #27292e !important;
  font-size: 0.8rem !important;
  color: white !important;
  width: 1rem !important;
  height: 1rem !important;
  border: 0px;
  top: 45%;
  &:disabled {
    visibility: hidden;
    cursor: none;
  }
`;

function renderArrow({ type, onClick, isEdge }: RenderArrowProps) {
  return (
    <SplunkThemeProvider family="prisma" density="compact" colorScheme="dark">
      <StyledButton onClick={onClick} disabled={isEdge}>
        {type === 'PREV' ? <ChevronLeft /> : <ChevronRight />}
      </StyledButton>
    </SplunkThemeProvider>
  );
}

function renderPagination({ pages, activePage, onClick }: RenderPaginationProps) {
  const shouldBeVisible = pages.length > 1;
  return (
    <div className="pagination-styling" style={{ marginTop: '10px' }}>
      {pages.map((page) => {
        const isActivePage = activePage === page;
        return (
          <PaginationButton
            key={page}
            onClick={() => onClick(`${page}`)}
            active={isActivePage}
            visible={shouldBeVisible}
          />
        );
      })}
    </div>
  );
}

const CollectionCardsCarousel: FC<CollectionsProps> = ({ collections }) => {
  return (
    <div className="pagination-styling">
      <Carousel
        itemsToScroll={2}
        itemsToShow={2}
        isRTL={false}
        renderArrow={renderArrow}
        renderPagination={renderPagination}
      >
        {collections.map((collection) => (
          <div key={collection.id}>
            <CollectionCard collection={collection} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CollectionCardsCarousel;
