import Head from 'next/head';

import AppsCarousel from '@/components/AppsCarousel/AppsCarousel';
import CollectionCardsCarousel from '@/components/CollectionCardsCarousel/CollectionCardsCarousel';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';
import HomeIntro from '@/components/HomeIntro/HomeIntro';
import HomeSection from '@/components/HomeSection/HomeSection';
import SubmitYourAppBanner from '@/components/SubmitYourAppBanner/SubmitYourAppBanner';
import { getNewSplunkApps, getPopularCloudApps, getPopularSOARApps } from '@/lib/api/api_v2/appsDetails';
import { getSortedCollections } from '@/lib/api/api_v2/collections';
import { getTrendingSplunkApps } from '@/lib/api/api_v3/trends';
import { logger } from '@/lib/logger';
import { contentColors } from '@/styles/layout';
import { AppCardData, Collection } from '@/types/splunkbaseTypes';

const pageInfo = {
  name: 'Get more out of Splunk with applications',
  description: 'Extend the capabilities of Splunk with custom applications.',
  img: '/home/hero.webm',
  imgW: 560,
  imgH: 511,
};
interface Props {
  allCollections: Collection[];
  trendingSplunkApps: AppCardData[];
  newSplunkApps: AppCardData[];
  popularSOARApps: AppCardData[];
  popularCloudApps: AppCardData[];
}

const Home = (props: Props) => {
  const collections = props.allCollections;
  const trendingSplunkApps = props.trendingSplunkApps;
  const newSplunkApps = props.newSplunkApps;
  const popularSOARApps = props.popularSOARApps;
  const popularCloudApps = props.popularCloudApps;

  const backgroundColorPage = `${contentColors.backgroundColorPage}`;
  const backgroundColorPageSecondary = `${contentColors.backgroundColorPageSecondary}`;

  return (
    <div>
      <Head>
        <title>Splunkbase | Home</title>
      </Head>
      <HomeSection backgroundColor={backgroundColorPage}>
        <HomeIntro pageInfo={pageInfo} />
      </HomeSection>
      <HomeSection backgroundColor={backgroundColorPageSecondary} head="Trending Apps on Splunkbase">
        <AppsCarousel apps={trendingSplunkApps} />
      </HomeSection>
      <HomeSection
        backgroundColor={backgroundColorPageSecondary}
        head="New Splunk Built and Supported Apps"
        link="/apps?author=splunk&sortBy=latest&page=1"
      >
        <AppsCarousel apps={newSplunkApps} />
      </HomeSection>
      <HomeSection backgroundColor={backgroundColorPageSecondary} head="Splunkbase Collections" link="/collections">
        <ErrorBoundary>{collections.length > 0 && <CollectionCardsCarousel collections={collections} />}</ErrorBoundary>
      </HomeSection>
      <HomeSection
        backgroundColor={backgroundColorPageSecondary}
        head="Most popular Splunk Cloud Apps"
        link="/apps?page=1&sortBy=popular&filters=product%3Asplunk%2Fproduct_types%3Acloud"
      >
        <AppsCarousel apps={popularCloudApps} />
      </HomeSection>
      <HomeSection
        backgroundColor={backgroundColorPageSecondary}
        head="Most popular SOAR Connectors"
        link="/apps?page=1&sortBy=popular&filters=product%3Asoar"
      >
        <AppsCarousel apps={popularSOARApps} />
      </HomeSection>
      <HomeSection backgroundColor={backgroundColorPageSecondary}>
        <div style={{ margin: '0 auto', width: '1110px' }}>
          <SubmitYourAppBanner />
        </div>
      </HomeSection>
    </div>
  );
};

export async function getStaticProps() {
  logger.info({
    method: 'getStaticProps',
    component: '/',
    msg: 'Collecting Home page static props',
  });

  const allCollections = await getSortedCollections();
  const trendingSplunkApps = await getTrendingSplunkApps();
  const newSplunkApps = await getNewSplunkApps();
  const popularSOARApps = await getPopularSOARApps();
  const popularCloudApps = await getPopularCloudApps();

  return {
    props: {
      allCollections,
      trendingSplunkApps,
      newSplunkApps,
      popularSOARApps,
      popularCloudApps,
    },
    revalidate: 4 * 60 * 60,
  };
}

export default Home;
